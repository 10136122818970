import imageCompression from "browser-image-compression";
export default {
  methods: {
    async handleImageUpload(event) {
      console.log(event);
      const imageFile = event;
      console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      const options = {
        // maximum image size to maintain
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        );
        //conver Blob to File format and return File
        const myFile = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
        });
        console.log("compressedFile", myFile);

        return myFile;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
